import LoginPage from '../../components/Admin/Login'
import React from 'react'
import Pagewrapper from '../../components/PageWrapper'

export default function () {
    return (
        <Pagewrapper>
            <LoginPage />
        </Pagewrapper>
    )
}

